import React from 'react';
import { Admin, Resource, Login } from 'react-admin'
import { createBrowserHistory } from 'history';
import authProvider from './Provider/AuthProvider'
import { dataProvider } from './Service/ApiService'
//  import errorSagas from './Service/Sagas/Error'
import { WtTheme } from './UI/WtTheme'
//import './App.css';
import './UI/Input/style.css';
/*icons import*/
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import IconAdmin from '@material-ui/icons/AccountBox';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import PlanUpgradeRequestIcon from '@mui/icons-material/SystemUpdateAlt';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CategoryIcon from '@mui/icons-material/Category';
/*component import*/
import Dashboard from './Components/Dashboard/Dashboard.js';
import { AdminUserList,AdminUserEdit,AdminUserCreate,AdminUserDetail} from './Components/AdminUser'
import { SboModulesList,SboModulesEdit,SboModulesCreate,SboModulesDetail} from './Components/SboModules'
import { ContentWriterKeywordList,ContentWriterKeywordEdit,ContentWriterKeywordCreate,ContentWriterKeywordDetail} from './Components/ContentWriterKeyword'
import { ContentWriterKeywordAmountList,ContentWriterKeywordAmountEdit,ContentWriterKeywordAmountCreate,ContentWriterKeywordAmountDetail} from './Components/ContentWriterKeywordAmount'
import { InfluencerRatingsList,InfluencerRatingsEdit,InfluencerRatingsCreate,InfluencerRatingsDetail} from './Components/InfluencerRatings'
import { SbocareLists,SbocareDetail,SbocareEdit} from './Components/Sbocare'
import { ContentWriterList,ContentWriterDetail} from './Components/ContentWriter'
import { ShopList,ShopEdit,ShopCreate,ShopDetail} from './Components/Shop'
import { ShopBankList,ShopBankEdit,ShopBankCreate,ShopBankDetail} from './Components/ShopBank'
import { LanguageVideoList,LanguageVideoEdit,LanguageVideoCreate,LanguageVideoDetail} from './Components/LanguageVideo'
import { UserList,UserDetail,UserEdit} from './Components/User'
import { StarHealthList,StarHealthDetail} from './Components/StarHealth'

import { PendingUserList,PendingUserDetail,PendingUserEdit} from './Components/PendingUser'
import { WithdrawRequestList,WithdrawRequestEdit,WithdrawRequestDetail} from './Components/WithdrawRequest'
import { DigitalTaskApplicationList,DigitalTaskApplicationEdit,DigitalTaskApplicationDetail} from './Components/DigitalTaskApplication'

import { InfluencerList,InfluencerEdit,InfluencerDetail,InfluencerAllList,InfluencerFullList,InfluencerFullDetail} from './Components/Influencer'
import { MicroTaskList,UserSearchList,MicrotaskVerifyList,UserCouponBuyList,UserWalletCountList} from './Components/Microtask'


import { PlanUpgradeRequestList,PlanUpgradeRequestDetail} from './Components/PlanUpgradeRequest'
import { VideoListList,VideoListEdit,VideoListCreate,VideoListDetail} from './Components/VideoList'
import { CategoryListList,CategoryListEdit,CategoryListCreate,CategoryListDetail} from './Components/CategoryList'

import { AgreementAndPaymentList,AgreementAndPaymentEdit,AgreementAndPaymentCreate,AgreementAndPaymentDetail} from './Components/AgreementAndPayment'

import { ShopKeywordList,ShopKeywordEdit,ShopKeywordCreate,ShopKeywordDetail} from './Components/ShopKeyword'
import { ShopCategoryList,ShopCategoryEdit,ShopCategoryCreate,ShopCategoryDetail} from './Components/ShopCategory'
import { IntroWithdrawRequestList,IntroWithdrawRequestEdit,IntroWithdrawRequestDetail} from './Components/IntroWithdrawRequest'
import { BuyBackWithdrawRequestList,BuyBackWithdrawRequestEdit,BuyBackWithdrawRequestDetail} from './Components/BuyBackWithdrawRequest'
import { WelcomeBonusWithdrawRequestList,WelcomeBonusWithdrawRequestEdit,WelcomeBonusWithdrawRequestDetail} from './Components/WelcomeBonusWithdrawRequest'
import { BasicPlanList,BasicPlanEdit,BasicPlanCreate,BasicPlanDetail} from './Components/BasicPlan'
import { PurchasePartnerDetailsList,PurchasePartnerDetailsEdit,PurchasePartnerDetailsDetail} from './Components/PurchasePartnerDetails'
import { UpgradePlanList,UpgradePlanEdit,UpgradePlanCreate,UpgradePlanDetail} from './Components/UpgradePlan'
import { BasicSettingsList,BasicSettingsEdit,BasicSettingsCreate,BasicSettingsDetail} from './Components/BasicSettings'
import { MyLayout} from './Components/Layout/MyLayout.js'
import { AddBonusCreate,AddBulckBonusCreate,AddBulckUserActiveCreate,AddBulckUserCouponPaymentActiveCreate,AddBulckUserWelcomeBonusCreate,SingleUserAddorDeductCreate,AdminBulckUserAddWorkingAmountCreate,AddBulckUserActiveWithIntroCreate,AddWithdrawPaymentInfoCreate} from './Components/AddBonus'
import { ArticleCreate, ArticleDetail, ArticleEdit, ArticleList } from './Components/Article';
import { CodeList,CodeEdit,CodeCreate,CodeDetail} from './Components/Code'
import { UserUtrList} from './Components/UserUtr'
import { MerchandiserApplyList,MerchandiserApplyDetail,MerchandiserApplyEdit} from './Components/MerchandiserApply'
import { UserCommandList,UserCommandDetail,UserCommandEdit} from './Components/UserCommands'
import { SboIncometaxApplicant} from './Components/SboIncometaxApplicant'
import {PublicCloudStorageList,PublicCloudStorageDetail} from './Components/PublicCloudStorage'
import { SiteCarouselSlideCreate,SiteCarouselSlideDetail,SiteCarouselSlideEdit} from './Components/SiteCarouselSlide';
import { SiteCarouselList,SiteCarouselCreate,SiteCarouselEdit,SiteCarouselDetail} from './Components/SiteCarousel';
import { UserBuyCouponPaymentInfoList, UserBuyCouponPaymentInfoDetail, UserBuyCouponPaymentInfoEdit } from './Components/UserBuyCouponPaymentInfo';
import {DeepavaliBonusList} from './Components/DeepavaliBonus';
import {OnlineCourse} from './Components/OnlineCourse/';
import RefundDocumentList from './Components/RefundDocument/RefundDocument.List.js';
import {RefundPaymentProofList } from './Components/RefundPaymentProof/index.js';
import {PurchasePartnerServiceList,PurchasePartnerServiceDetail} from './Components/PurchasePartnerService';
const MyLoginPage = () => <Login />;

const history = createBrowserHistory({
  basename: '/sboadmin'
});
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en');
const App = () => (

  <Admin loginPage={MyLoginPage} layout={MyLayout} history={history} i18nProvider={i18nProvider} dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider}>
    <Resource ></Resource>  
    <Resource name="adminuser" options={{ label: "Admin User" }} list={AdminUserList} create={AdminUserCreate} edit={AdminUserEdit} show={AdminUserDetail}  icon={IconAdmin}/>  
    <Resource name="sbomodules" options={{ label: "Sbo Modules" }} list={SboModulesList} create={SboModulesCreate} edit={SboModulesEdit} show={SboModulesDetail}  icon={IconAdmin}/>  
    <Resource name="shop" options={{ label: "Shop" }} list={ShopList} create={ShopCreate} edit={ShopEdit} show={ShopDetail}  icon={AddShoppingCartIcon}/>   
    <Resource name="shopbank" options={{ label: "Shop Bank" }} list={ShopBankList} create={ShopBankCreate} edit={ShopBankEdit} show={ShopBankDetail}  icon={AccountBalanceIcon}/>   
    <Resource name="pendinguser" options={{ label: "Pending Users" }} list={PendingUserList} edit={PendingUserEdit} show={PendingUserDetail} icon={IconAdmin}/>
    <Resource name="adminbulckuseractivewithintro" options={{ label: "New Joiner Bulck Active" }} create={AddBulckUserActiveWithIntroCreate} icon={CurrencyExchangeIcon}/>
    <Resource name="user" options={{ label: "Users" }} list={UserList} edit={UserEdit} show={UserDetail} icon={AccountCircleIcon}/>


    <Resource name="healthcare" options={{ label: "Star Health" }} list={StarHealthList}  show={StarHealthDetail} icon={AccountCircleIcon}/>
    <Resource name="purchasepartnersdetails" options={{ label: "purchase marketing details" }} list={PurchasePartnerDetailsList} edit={PurchasePartnerDetailsEdit} show={PurchasePartnerDetailsDetail} icon={AccountCircleIcon}/>


    <Resource name="healthcarefamily"   />


    <Resource name="withdrawrequest" options={{ label: "Withdraw Request" }} list={WithdrawRequestList} edit={WithdrawRequestEdit} show={WithdrawRequestDetail} icon={CurrencyExchangeIcon}/>
    <Resource name="digitaltaskapplication" options={{ label: "Digital Task Request" }} list={DigitalTaskApplicationList} edit={DigitalTaskApplicationEdit} show={DigitalTaskApplicationDetail} icon={CurrencyExchangeIcon}/>
    <Resource name="influencer" options={{ label: "Influencer Task - Your List " }} list={InfluencerList} edit={InfluencerEdit} show={InfluencerDetail} icon={CurrencyExchangeIcon}/>

    <Resource name="usercouponpaymentinfobuy" options={{ label: "User coupon buy payment info" }} list={UserBuyCouponPaymentInfoList} edit={UserBuyCouponPaymentInfoEdit} show={UserBuyCouponPaymentInfoDetail} icon={CurrencyExchangeIcon}/>

    <Resource name="microtask" options={{ label: "MicroTask" }} list={MicroTaskList} icon={CurrencyExchangeIcon}/>
    <Resource name="usercouponbuy" options={{ label: "User Coupon Buy List" }} list={UserCouponBuyList} icon={CurrencyExchangeIcon}/>
    <Resource name="usersearch" options={{ label: "User Team Info" }} list={UserSearchList} icon={CurrencyExchangeIcon}/>

    <Resource name="microtaskverifyuser" options={{ label: "Micro Task Verify User" }} list={MicrotaskVerifyList} icon={CurrencyExchangeIcon}/>

    <Resource name="userwalletcount" options={{ label: "User Wallet Info" }} list={UserWalletCountList} icon={CurrencyExchangeIcon}/>
    
    <Resource name="influencerall" options={{ label: "All Influencer Task" }} list={InfluencerFullList} show={InfluencerFullDetail}icon={CurrencyExchangeIcon}/>
    <Resource name="influencerrating" options={{ label: "Influencer Rating" }} list={InfluencerRatingsList} create={InfluencerRatingsCreate} edit={InfluencerRatingsEdit} show={InfluencerRatingsDetail}  icon={IconAdmin}/>  
    <Resource name="sbocare" options={{ label: "SBO Care" }} list={SbocareLists}  edit={SbocareEdit} show={SbocareDetail}  icon={IconAdmin}/>  

    <Resource name="planupgraderequest" options={{ label: "Plan Upgrade Request" }} list={PlanUpgradeRequestList} show={PlanUpgradeRequestDetail} icon={PlanUpgradeRequestIcon}/>
    <Resource name="code" options={{ label: "Code" }} list={CodeList} create={CodeCreate} edit={CodeEdit} show={CodeDetail}/>
    <Resource name="userutr" options={{ label: "Users Utr" }} list={UserUtrList} icon={AccountCircleIcon}/>
    <Resource name="deepavalibonus" options={{ label: "Deepavali Bonus" }} list={DeepavaliBonusList} icon={AccountCircleIcon}/>
    <Resource name="merchandiserapply" options={{ label: "Merchandiser Apply list" }} list={MerchandiserApplyList} edit={MerchandiserApplyEdit} show={MerchandiserApplyDetail} icon={AccountCircleIcon}/>
    <Resource name="usercommands" options={{ label: "User Commands" }} list={UserCommandList} edit={UserCommandEdit} show={UserCommandDetail} icon={IconAdmin}/>
    <Resource name="languagevideo" options={{ label: "Language Video" }} list={LanguageVideoList} create={LanguageVideoCreate} edit={LanguageVideoEdit} show={LanguageVideoDetail}/>
    <Resource name="videolist" options={{ label: "Video List" }} list={VideoListList} create={VideoListCreate} edit={VideoListEdit} show={VideoListDetail}/>
    <Resource name="agreementandpayment" options={{ label: "Agreement And Payment  User List" }} list={AgreementAndPaymentList} create={AgreementAndPaymentCreate} edit={AgreementAndPaymentEdit} show={AgreementAndPaymentDetail}/>


    <Resource name="categorylist" options={{ label: "Category List" }} list={CategoryListList} create={CategoryListCreate} edit={CategoryListEdit} show={CategoryListDetail}/>
    <Resource name="shopcategory" options={{ label: "Shop Category" }} list={ShopCategoryList} create={ShopCategoryCreate} edit={ShopCategoryEdit} show={ShopCategoryDetail} icon={CategoryIcon}/>
    <Resource name="shopkeyword" options={{ label: "Shop Keyword" }} list={ShopKeywordList} create={ShopKeywordCreate} edit={ShopKeywordEdit} show={ShopKeywordDetail} icon={CategoryIcon}/>

    <Resource name="basicplan" options={{ label: "Plan" }} list={BasicPlanList} create={BasicPlanCreate} edit={BasicPlanEdit} show={BasicPlanDetail}/>
    <Resource name="upgradeplan" options={{ label: "Upgrade Plan" }} list={UpgradePlanList} create={UpgradePlanCreate} edit={UpgradePlanEdit} show={UpgradePlanDetail}/>
    <Resource name="introwithdrawrequest" options={{ label: "Intro Bonus Withdraw Request" }} list={IntroWithdrawRequestList} edit={IntroWithdrawRequestEdit} show={IntroWithdrawRequestDetail} icon={CurrencyExchangeIcon}/>
    <Resource name="buybackwithdrawrequest" options={{ label: "Buy Back Point Withdraw Request" }} list={BuyBackWithdrawRequestList} edit={BuyBackWithdrawRequestEdit} show={BuyBackWithdrawRequestDetail} icon={CurrencyExchangeIcon}/>
    <Resource name="welcomebonuswithdrawrequest" options={{ label: "Welcome Bonus Withdraw Request" }} list={WelcomeBonusWithdrawRequestList} edit={WelcomeBonusWithdrawRequestEdit} show={WelcomeBonusWithdrawRequestDetail} icon={CurrencyExchangeIcon}/>
    <Resource name="adminbulckuseractive" options={{ label: "Bulck User Active" }} create={AddBulckUserActiveCreate} icon={CurrencyExchangeIcon}/>

    <Resource name="adminbulckusercouponpaymentactive" options={{ label: "Bulck User coupon buy payment info Active" }} create={AddBulckUserCouponPaymentActiveCreate} icon={CurrencyExchangeIcon}/>

    <Resource name="withdrawpaymentinfo" options={{ label: "Withdraw Payment Info" }} create={AddWithdrawPaymentInfoCreate} icon={CurrencyExchangeIcon}/>
    
    <Resource name="contentwriterkeywords" options={{ label: "Content Writer Keyword" }} list={ContentWriterKeywordList} create={ContentWriterKeywordCreate} edit={ContentWriterKeywordEdit} show={ContentWriterKeywordDetail}  icon={IconAdmin}/>  
    <Resource name="contentwriterkeywordamount" options={{ label: "Content Writer Keyword Amount" }} list={ContentWriterKeywordAmountList} create={ContentWriterKeywordAmountCreate} edit={ContentWriterKeywordAmountEdit} show={ContentWriterKeywordAmountDetail}  icon={IconAdmin}/>  
    <Resource name="contentwriter" options={{ label: "Content Writer" }} list={ContentWriterList}  show={ContentWriterDetail}  icon={IconAdmin}/>  
    <Resource name="purchasepartnerservice" options={{ label: "Purchase Partner Service" }} list={PurchasePartnerServiceList}  show={PurchasePartnerServiceDetail}  icon={IconAdmin}/>  
   
    <Resource name="article" options={{ label: "Article" }} list={ArticleList} create={ArticleCreate} edit={ArticleEdit} show={ArticleDetail}/>
    <Resource name="adminsingleuseraddordeduct" options={{ label: "Single User Add or Deduct" }} create={SingleUserAddorDeductCreate} icon={CurrencyExchangeIcon}/>
    <Resource name="adminbulckuseraddworkingamount" options={{ label: "Bulck Add Working Non-working" }} create={AdminBulckUserAddWorkingAmountCreate} icon={CurrencyExchangeIcon}/>

    <Resource name="adminbulckuseraddwelcomebonus" options={{ label: "Bulck User Add Welcome Bonus" }} create={AddBulckUserWelcomeBonusCreate} icon={CurrencyExchangeIcon}/>
    <Resource name="adminbonusadd" options={{ label: "Add Bonus" }} create={AddBonusCreate} icon={CurrencyExchangeIcon}/>
    <Resource name="adminbulckbonusadd" options={{ label: "Add Bulck Bonus" }} create={AddBulckBonusCreate} icon={CurrencyExchangeIcon}/>
    <Resource name="basicsettings" options={{ label: "General Settings" }} list={BasicSettingsList} create={BasicSettingsCreate} edit={BasicSettingsEdit} show={BasicSettingsDetail}/>
  
    <Resource name="sboincometaxapplicant" options={{ label: "SBO Income Tax Applicant" }} list={SboIncometaxApplicant}  icon={IconAdmin}/>
    <Resource name="refunddocument" options={{ label: "Refund Documents" }} list={RefundDocumentList}  icon={IconAdmin}/>
    <Resource name="refundpaymentproof" options={{ label: "Refund Payment proof" }} list={RefundPaymentProofList}  icon={IconAdmin}/>

    <Resource name="onlinecourse" options={{ label: "Online Course Member List" }} list={OnlineCourse}  icon={IconAdmin}/>

    <Resource name="publiccloudstoragelist" options={{ label: "Public Cloud Storage" }} list={PublicCloudStorageList} show={PublicCloudStorageDetail} icon={CloudDoneOutlinedIcon}/>
    <Resource name="adminpermissions" />    
    <Resource name="sitecarousel" options={{ label: "Site Carousel" }} list={ SiteCarouselList}  create={SiteCarouselCreate} edit={ SiteCarouselEdit} show={SiteCarouselDetail}/>
    <Resource name="sitecarouselslide" options={{ label: "Site Carousel Slide" }}  create={SiteCarouselSlideCreate}  show={SiteCarouselSlideDetail} edit={SiteCarouselSlideEdit}/>
    <Resource name={"shopcategorylist"} />,
  </Admin>

)
export default App