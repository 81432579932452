import React, { Fragment, useState, useMemo } from 'react'
import {
    List, Datagrid, Filter, ShowButton, Button, Toolbar, SaveButton, SimpleForm, useRefresh, SelectInput, required, FormDataConsumer, TopToolbar,useNotify, useListContext, TextInput, TextField, BulkDeleteButton, FunctionField, useRecordContext, Link
} from 'react-admin'
import { PurchasePartnerServiceStatusSelectInput,AdminUserSelectInput,PurchasePartnerServiceSelectInput } from '../../UI/Input/SelectInput'
import { stringify } from 'query-string';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DateRangeInput } from '../../UI/Input/input';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions';
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";
import BulkActiveButton from '../PurchasePartnerService/Action/Bulk.Active.Button';
import BulkRejectButton from '../PurchasePartnerService/Action/Bulk.Reject.Button';
import { actionOfMicroAndContentWritter } from '../../Model/actionOfMicroAndContentWritter';

const ListFilter = (props) => {
    const {

        setFilters,
        hideFilter
    } = useListContext();

    return (<Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />             
        <AdminUserSelectInput label="Admin User" source="adminuser" alwaysOn />
        <PurchasePartnerServiceStatusSelectInput label="Status" source="status" alwaysOn/>
        <PurchasePartnerServiceSelectInput label="Services" source="service" alwaysOn/>
    </Filter>
    )
}
const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
        <BulkActiveButton label="Bulck Active"  {...props} />
        <BulkRejectButton label="Bulck Rejectss"  {...props} />
    </Fragment>)
  }
const ListActions  = (props) => {
    const record = useListContext();
    const filva = record.filterValues ? stringify(record.filterValues) : ''; 
    return ( <TopToolbar>
      <Button href={`//sboportal.org.in/admin/api/v1/purchasepartnerservice/export?${filva}`} label="Purchase Partner Service Export" ><DownloadIcon /></Button>
  </TopToolbar>)
}
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.user.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}

const SanitizedGrid = ({ basePath, ...props }) => {
    return <Grid {...props} />;
};
const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Submit" redirect={null} submitonenter={false} />
        {!props.saving && <Button label="ra.action.cancel" onClick={props.onCancel}>
            <IconCancel />
        </Button>}
    </Toolbar>
)



const PurchasePartnerServiceList = ({ ...props }) => {


    const [open, setOpen] = useState(false);
    const refresh = useRefresh()
    const notify = useNotify()

    const [inputValue, setInputValue] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [recordStatus, setRecordStatus] = useState(null);
    const handleOpenDialog = (record) => {

        setSelectedId(record.id);
        setRecordStatus(record.status);
        setOpen(true);
    }
    const handleCloseDialog = () => setOpen(false);


    // Function to handle submit action in the dialog
    const handleSubmit = (record) => {

        const data = {
            "rid": selectedId,
            "type": 'purchasepartnerservice',
            "status": record.status,
            "reason": record.rejected_reason
        }
        actionOfMicroAndContentWritter(data, selectedId, (res) => {
            refresh()
            notify(res.message)
        }, (res) => {
            notify(res.message, 'warning')
        })
        setOpen(false);

    };

    const choices = useMemo(() => {

        if (recordStatus === 'Pending') {
            return [
                { id: 'Approved', name: 'Approved' },
                { id: 'Rejected', name: 'Rejected' },
            ]
        } else if (recordStatus === 'Approved') {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Rejected', name: 'Rejected' },
            ]
        } else {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Approved', name: 'Approved' },
            ]
        }
    }, [recordStatus]);

    const OpenDialogButton = ({ onClick }) => {
        const record = useRecordContext(); // Access the current record context
        if (!record) return null; // Ensure record exists
        return (

            <Button style={{ color: 'purple', border: '1px solid' }} onClick={() => onClick(record)} label="Update Action" size="small"></Button>
        );
    };


    return (
        <Fragment>
            <List {...props} actions={<ListActions />} title="Purchase Partner Service" bulkActionButtons={<BulkActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

                <Datagrid >
                    <ProfileidFiled source="user.profile_id" label="Profile Id" />
                    <TextField source='user.name' label="Name" />
                    <TextField source='phone' label="Active contact number" />
                    <TextField source="whatsapp" label="Whatsapp Number" />
                    <TextField source="email" label="Active Email Id" />
                    <TextField source="services" label="Service" />                    
                    <FunctionField source="status" label="Status" render={record => {
                        if (record['status'] === 'Rejected') {
                            return (<span style={{ color: 'red' }}>Rejected</span>)
                        } else if (record['status'] === 'Pending') {
                            return (<span style={{ color: 'orange' }} >Pending</span>)
                        }
                        else if (record['status'] === 'Cancelled') {
                            return (<span style={{ color: 'blue' }} >Cancelled</span>)
                        }
                        else {
                            return (<span style={{ color: 'green' }}>Approved</span>)
                        }
                    }} />
                    {useCheckPermissions('PurchasePartnerService_Edit') ? <OpenDialogButton onClick={handleOpenDialog} /> : null}                                       
                    {useCheckPermissions('PurchasePartnerService_View') ? <ShowButton /> : null}
                    <Dialog maxWidth='900px' open={open} onClose={handleCloseDialog}>
                    <DialogTitle> Record Id - {selectedId} {recordStatus}</DialogTitle>

                    <SimpleForm sx={{ minWidth: '400px', }} onSubmit={(record) => handleSubmit(record)} toolbar={<FormToolbar onCancel={() => { handleCloseDialog() }} />} >
                        <SanitizedGrid container spacing={3} fullWidth>
                            <Grid item sm={12} lg={12}>
                                <SelectInput validate={required()} source="status" label="Status" fullWidth choices={choices}{...props} />


                            </Grid>
                            <Grid item sm={12} lg={12}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) =>
                                        formData.status === "Rejected" && (
                                            <TextInput source="rejected_reason" label="Reason" fullWidth resettable multiline validate={required()} />
                                        )
                                    }
                                </FormDataConsumer>

                            </Grid>
                        </SanitizedGrid>
                    </SimpleForm>


                </Dialog>
                </Datagrid>
                {/* Dialog Component */}
               

            </List>
        </Fragment>
    )
}


export default PurchasePartnerServiceList