import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
const BasicSettingsDetail = ({...props }) => (

    <Show title="Basic Plan Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="total_user_count_admin" label="User Count" />
            <TextField source="waiting_video_id" label="Waiting page Video Id" />
            <TextField source="demo_user_task_end_date" label="New User Demo Task End Date" />
                 <TextField source="user_task_end_date" label="Old User Demo Task End Date" />
            {/* <TextField source="userwallet_min_amount" label="User Wallet Min Amount" />
            <TextField source="videowallet_min_amount" label="Video Wallet Min Amount" />
            <TextField source="admincharge" label="Admin Charge" />
            <TextField source="basic_user_refer_amount" label="Basic User Refer Amont" />
            <TextField source="primium_user_refer_amont" label="Primium User Refer Amont" />
            <TextField source="email" label="Email" />
            <TextField source="companey_name" label="Companey Name" />
            <TextField source="phone" label="Phone" />
            <TextField source="address" label="Address" />
            <TextField source="points_per_rupee" label="Reward point per rupee" />
            <TextField source="reward_percentage" label="Reward percentage to sponsor" /> */}
           
        </SimpleShowLayout>
    </Show>
     );
export default BasicSettingsDetail