import React, { useState, useMemo, Fragment } from 'react'
import {
    List, Datagrid, Filter, TopToolbar, DateInput, Button, useListContext, ShowButton, SelectInput, required, FormDataConsumer, useNotify, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { stringify } from 'query-string';
import DownloadIcon from '@material-ui/icons/GetApp';
import { InfluencerStatusSelectInput } from '../../UI/Input/SelectInput'
import BulkActiveButton from '../StarHealth/Action/Bulk.Active.Button';
import BulkRejectButton from '../StarHealth/Action/Bulk.Reject.Button';

const ListActions  = (props) => {
      const record = useListContext();
      const filva = record.filterValues ? stringify(record.filterValues) : ''; 
      return ( <TopToolbar>
        <Button href={`//sboportal.org.in/admin/api/v1/starhealth/export?${filva}`} label="Star Health Export" ><DownloadIcon /></Button>
    </TopToolbar>)
}
const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
         <InfluencerStatusSelectInput label="Status" source="status" alwaysOn/>
    </Filter>
)


const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
        <BulkActiveButton label="Bulck Active"  {...props} />
        <BulkRejectButton label="Bulck Rejectss"  {...props} />
    </Fragment>)
  }

const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}




const StarHealthList = ({ ...props }) => {

    return (
        <List {...props} actions={<ListActions />}   title="Star Health" bulkActionButtons={<BulkActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <ProfileidFiled source="user.profile_id" label="Profile Id" />
                <TextField source='name' label="Name" />
                <FunctionField source="status" label="Status" render={record => {

                    if (record['status'] === 'Rejected') {
                        return (<span style={{ color: 'red' }}>Reject</span>)
                    } else if (record['status'] === 'Pending') {
                        return (<span style={{ color: 'orange' }} >Pending</span>)
                    }
                    else {
                        return (<span style={{ color: 'green' }}>Approved</span>)
                    }
                }} />
               
                <TextField source='created_at' label="Date" />
                <ShowButton />
            </Datagrid>
        </List>
    )

}


export default StarHealthList 