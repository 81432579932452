import * as React from "react";
import { Show, ShowController, ReferenceManyField, Pagination, TabbedShowLayout, Datagrid, List, RichTextField, Tab, useRecordContext, Button, TextField, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'
import { Link } from 'react-router-dom';
const PageTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.name}` : ''}</span>;
};
const PostPagination = ({ record, ...props }) => {
  return (<Pagination rowsPerPageOptions={[10, 20, 30, 50, 100, { value: props.total, label: 'All' }]} {...props} />)
}
const ProfileidFiled = (props) => {
  const record = useRecordContext();
  return (
    <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
  )
}

const StarHealthDetail = ({ ...props }) => {
  const classes = useStyles();
  const useperview = useCheckPermissions('StarHealth_View');

  return (
    <ShowController {...props}>
      {controllerProps =>
        <Show title="User Detail "  {...props}>

          <TabbedShowLayout>
            <Tab label="Summary">
              <ProfileidFiled source="user.profile_id" label="Profile Id" />
              <TextField source='user.name' label="User Name" />
               <TextField source='name' label="Proposer Name" />
              <FunctionField source="status" label="Status" render={record => {

                if (record['status'] === 'Rejected') {
                  return (<span style={{ color: 'red' }}>Reject</span>)
                } else if (record['status'] === 'Pending') {
                  return (<span style={{ color: 'orange' }} >Pending</span>)
                }
                else {
                  return (<span style={{ color: 'green' }}>Approved</span>)
                }
              }} />


              <TextField source='doornumber' label='Door Number' />
              <TextField source='streetname' label='Street Name' />
              <TextField source='cityname' label='City Name' />
              <TextField source='postalcode' label='Postal Code' />
              <TextField source='taluck' label='Taluck' />
              <TextField source='district' label='District' />
              <TextField source='mobile' label='Mobile' />
              <TextField source='dob' label='Date of Birth' />
              <TextField source='email' label='Email' />
              <TextField source='weight' label='Weight' />
              <TextField source='height' label='Height' />
              <TextField source='pancard' label='Pan Card' />
              <TextField source='occupation' label='Occupation' />
              <TextField source='anualincome' label='Annual Income' />
              <TextField source='nominee_name' label='Nominee Name' />
              <TextField source='nominee_relation' label='Nominee Relation' />
              <TextField source='nominee_dob' label='Nominee Date of Birth' />
              <TextField source='nominee_pancard' label='Nominee Pan Card' />
              <TextField source='nominee_aadhaar' label='Nominee Aadhaar' />
              <TextField source='pre_existing_deseas' label='Pre Existing Diseases' />
              <TextField source='pre_existing_deseas_duration' label='Pre Existing Diseases Duration' />
              <TextField source='taking_medicine' label='Taking Medicine' />
              <TextField source='taking_medicine_duration' label='Taking Medicine Duration' />
              <TextField source='issurgery' label='Is Surgery' />
              <TextField source='surgery_commends' label='Surgery Comments' />
              <TextField source='family_count' label='Family Count' />
              <TextField source='approved_by' label='Approved By' />
              <TextField source='Rejected_by' label='Rejected By' />
              <TextField source='Rejected_reason' label='Rejected Reason' />
              <FunctionField source="proposer_photo" label="Proposer Photo" render={record => {
                if (record['proposer_photo']) {
                  return (<img style={{ maxWidth: '100px' }} src={record['proposer_photo']} alt={record.name} />)
                }
              }} />
              <FunctionField source="proposer_aadhaar_front" label="Proposer Aadhaar Front" render={record => {
                if (record['proposer_aadhaar_front']) {
                  return (<img style={{ maxWidth: '100px' }} src={record['proposer_aadhaar_front']} alt={record.name} />)
                }
              }} />
              <FunctionField source="proposer_aadhaar_back" label="Proposer Aadhaar Back" render={record => {
                if (record['proposer_aadhaar_back']) {
                  return (<img style={{ maxWidth: '100px' }} src={record['proposer_aadhaar_back']} alt={record.name} />)
                }
              }} />
              <FunctionField source="proposer_pan" label="Proposer Pan" render={record => {
                if (record['proposer_pan']) {
                  return (<img style={{ maxWidth: '100px' }} src={record['proposer_pan']} alt={record.name} />)
                }
              }} />
              <FunctionField source="proposer_bank_book" label="Proposer Bank Book" render={record => {
                if (record['proposer_bank_book']) {
                  return (<img style={{ maxWidth: '100px' }} src={record['proposer_bank_book']} alt={record.name} />)
                }
              }} />




              <TextField source='approved_date' label='Approved Date' />
              <TextField source='Rejected_at' label='Rejected At' />
              <TextField source='created_at' label="Date" />
            </Tab>
            {useperview ?
              <Tab label="Family Details">
                <ReferenceManyField pagination={<PostPagination />} reference={'healthcarefamily'} target="id" label="" filter={{ profile_id: controllerProps.record ? controllerProps.record.health_id : 0 }} addLabel={false} >
                  <Datagrid bulkaction={null} classes={{ row: classes.row }} >
                    <TextField source="id" />
                    <TextField source="name" label="Name" sortable={false} />
                    <TextField source="dob" label="dob" sortable={false} />
                    <TextField source="anualincome" label="Annual Income" sortable={false} />
                    <TextField source="occupation" label="Occupation" sortable={false} />
                    <TextField source="gender" label="Gender" sortable={false} />
                    <TextField source="weight" label="Weight" sortable={false} />
                    <TextField source="height" label="Height" sortable={false} />
                    <TextField source="pre_existing_deseas" label="Pre Existing Deseas" sortable={false} />
                    <TextField source="pre_existing_deseas_duration" label="Pre Existing Deseas Duration" sortable={false} />
                    <TextField source="surgery_commends" label="Surgery Commends" sortable={false} />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              : null}


          </TabbedShowLayout>
        </Show>
      }
    </ShowController>
  )
};
const useStyles = makeStyles({
  row: { verticalAlign: "top" },
  actionCell: { width: 100 },
  imageCell: { width: 160 },
  idCell: { width: 80 }
});
export default StarHealthDetail