import React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  Toolbar,
  ListButton,
  SaveButton,
  DateInput,
} from "react-admin";

import { RichTextInput } from 'ra-input-rich-text';

import { onBasicSettingsEdit } from "../../Service/Validation/BasicSettingsValidation";
import Grid from "@material-ui/core/Grid";

const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};

const PageTitle = ({ record }) => {
  return <span>Basic Plan: {record ? record.name : ""}</span>;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const BasicSettingsEdit = (props) => {
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} defaultValues={{ action: "full" }} validate={onBasicSettingsEdit} redirect="list">
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} md={12} lg={6}>
            <NumberInput label="Total User Count" source="total_user_count_admin" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <TextInput label="Waiting page Video Id" source="waiting_video_id" fullWidth />
          </Grid>

          <Grid item sm={12} md={4} lg={4}>

            <DateInput source="demo_user_task_end_date" label="New User Demo Task End Date" fullWidth />

          </Grid>
          <Grid item sm={12} md={4} lg={4}>

            <DateInput source="user_task_end_date" label="Old User Demo Task End Date" fullWidth />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <RichTextInput label="Microtask Content" source="microtask_content" fullWidth />
          </Grid>
          {/* <Grid item sm={12} md={4} lg={4}>
        <TextInput label="User Wallet Min Amount" source="userwallet_min_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Video Wallet Min Amount" source="videowallet_min_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Admin Charge" source="admincharge" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Basic User Refer Amont" source="basic_user_refer_amount" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Primium User Refer Amont" source="primium_user_refer_amont" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Email" source="email" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Companey Name" source="companey_name" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Phone" source="phone" fullWidth/>
        </Grid>

        <Grid item sm={12} md={4} lg={4}>
        <TextInput label="Address" source="address" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Reward point per rupee" source="points_per_rupee" fullWidth/>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
        <NumberInput label="Reward percentage to sponsor" source="reward_percentage" fullWidth/>
        </Grid>
        */}

        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default BasicSettingsEdit;
