import React,{Fragment} from "react";
import {
  List,
  Datagrid,
  Filter,
  EditButton,
  Button,
  useRecordContext,
  Link,
  TextInput,
  TextField,
  FunctionField,
  ShowButton
} from "react-admin";
import BulkActiveButton from '../PurchasePartnerDetails/Action/Bulk.Active.Button';
import BulkRejectButton from '../PurchasePartnerDetails/Action/Bulk.Reject.Button';
import {PurchasePartnerDetailsStatusSelectInput} from '../../UI/Input/SelectInput'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const BulkActionButtons = ({  ...props }) => {

  return (<Fragment>
      <BulkActiveButton label="Bulck Active"  {...props} />

      <BulkRejectButton label="Bulck Reject"  {...props} />

  </Fragment>)
}
const ProfileidFiled = (props)=>{
  const record = useRecordContext();  
  return (
  <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
)}
const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
    <PurchasePartnerDetailsStatusSelectInput label="Status" source="status" alwaysOn/>  
  </Filter>
);

const PlanUpgradeRequestList = ({ ...props }) => {
 
  return (
    <Fragment>
    <List
      {...props}
      title="Purchase Partner Details "
      bulkActionButtons={<BulkActionButtons />}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid >
        <TextField source="id" />
        <ProfileidFiled source="user.profile_id" label="Profile Id" /> 
        <TextField source="phone" label="Phone" /> 
        <TextField source="email" label="Email" /> 
        <TextField source="order_id" label="Order Id" /> 
        <TextField source="services" label="Services" /> 
        <TextField source="purchase_date" label="Purchase Date" /> 
        <FunctionField source="status" label="Status" render={record => {  
           if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Approved'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Approved" size="small"></Button>)
          }else if(record['status'] === 'Cancelled'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Cancelled" size="small"></Button>)
          }  else if(record['status'] === 'Rejected'){
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Rejected" size="small"></Button>)
          }                       
          }} /> 
           {useCheckPermissions('PurchasePartnerDetails_Edit')?<FunctionField source="status" label="Edit Action" render={record => {  
            if(record['status'] === 'Pending') {
              return (  <EditButton/>  )            
            }                         
          }} /> :null}
                {useCheckPermissions('PurchasePartnerDetails_View')?<ShowButton/>:null}
      </Datagrid>
    </List>
      
       </Fragment>
  );
};

export default PlanUpgradeRequestList;
