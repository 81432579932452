import React from 'react'
import { SelectInput, ReferenceInput, AutocompleteInput, required } from 'react-admin'

export const StatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Blocked', name: 'Blocked' },
        ]} {...props} />
    )
}
export const SboModulesStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Deactive', name: 'Deactive' },
        ]} {...props} />
    )
}
export const AdminUserRoleSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'SuperAdmin', name: 'SuperAdmin' },
            { id: 'Admin', name: 'Admin' },
        ]} {...props} />
    )
}

export const ContentWriterStatusSelectInput = (props) => {
    return (
        <SelectInput fullWidth choices={[
            { id: 'Approved', name: 'Approved' },
            { id: 'Reject', name: 'Reject' },
            { id: 'Pending', name: 'Pending' },
        ]} {...props} />
    )
}
export const PurchasePartnerServiceStatusSelectInput = (props) => {
    return (
        <SelectInput fullWidth choices={[
            { id: 'Approved', name: 'Approved' },
            { id: 'Rejected', name: 'Rejected' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Cancelled', name: 'Cancelled' }
        ]} {...props} />
    )
}
export const PurchasePartnerServiceSelectInput = (props) => {
    return (
        <SelectInput fullWidth choices={[
            { id: 'Starhealth', name: 'Starhealth' },
            { id: 'Buymote', name: 'Buymote' },
            { id: 'Eduquest', name: 'Eduquest' }
        ]} {...props} />
    )
}

export const ContentWriterKeywordStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Deactive', name: 'Deactive' },
        ]} {...props} />
    )
}

export const ActionSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Add', name: 'Add' },
            { id: 'Deduct', name: 'Deduct' },
        ]} {...props} />
    )
}



export const Status2SelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Deactive', name: 'Deactive' },
        ]} {...props} />
    )
}

export const SiteCarouselStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Inactive', name: 'Inactive' },
        ]} {...props} />
    )
}
export const SiteCarouselNavigationSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Arrow', name: 'Arrow' },
            { id: 'Dots', name: 'Dots' },
            { id: 'None', name: 'None' },
        ]} {...props} />
    )
}
export const SiteCarouselAutoplaySelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Yes', name: 'Yes' },
            { id: 'No', name: 'No' },
        ]} {...props} />
    )
}
export const SiteCarouselAnimationSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Slide', name: 'Slide' },
            { id: 'Fade', name: 'Fade' },
        ]} {...props} />
    )
}
export const SiteCarouselPageSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'home', name: 'Home' },
        ]} {...props} />
    )
}
export const SiteCarouselSiteSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'sbo', name: 'sbo' },
            { id: 'others', name: 'others' },
        ]} {...props} defaultValue="sbo" />
    )
}
export const SiteCarouselSelectInput = (props) => {

    return (
        <ReferenceInput reference={'sitecarousel'} filter={{ filedtype: 'refernce' }} source="id" perPage={30} {...props} fullWidth>

            <AutocompleteInput optionText={(record) => { return record ? (record.page ? record.page : '') : '' }} fullWidth />
        </ReferenceInput>
    )

}
export const SiteCarouselSlidePositionSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'TopLeft', name: 'Top Left' },
            { id: 'TopRight', name: 'Top Right' },
            { id: 'TopCenter', name: 'Top Center' },
            { id: 'BottomLeft', name: 'Bottom Left' },
            { id: 'BottomRight', name: 'Bottom Right' },
            { id: 'BottomCenter', name: 'Bottom Center' },
            { id: 'Center', name: 'Center' },
            { id: 'LeftCenter', name: 'Left Center' },
            { id: 'RightCenter', name: 'Right Center' },

        ]} {...props} defaultValue="Center" />
    )
}
export const SiteCarouselSlideStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Inactive', name: 'Inactive' },
        ]} {...props} />
    )
}
export const ShopCategoryStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Inactive', name: 'Inactive' },
        ]} {...props} />
    )
}

export const VideoTypeSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Video', name: 'Video' },
            { id: 'Youtube', name: 'Youtube' },
        ]} {...props} />
    )
}

export const BasicPlanSelectInput = (props) => {

    return (
        <ReferenceInput reference={'basicplan'} filter={{ filedtype: 'refernce' }} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="plan_name" />
        </ReferenceInput>
    )
}
export const UserBasicPlanSelectInput = (props) => {
    return (
        <ReferenceInput reference={'basicplan'} filter={{ filedtype: 'refernce' }} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="plan_name" />
        </ReferenceInput>
    )
}

export const InfluencerRatingBasedSelectInput = (props) => {
    return (
        <ReferenceInput filter={{ type: 'Influencer', filedtype: 'refernce' }} reference={'influencerrating'} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="rating_count" />
        </ReferenceInput>
    )
}

export const InfluencerRatingBasedSelectNewInput = (props) => {
    return (
        <ReferenceInput filter={{ type: 'Influencer', filedtype: 'refernce' }} reference={'influencerrating'} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="rating_count" validate={required()} />

        </ReferenceInput>
    )
}

export const ShopSelectInput = (props) => {
    return (
        <ReferenceInput reference={'shop'} filter={{ filedtype: 'refernce' }} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    )
}

export const AdminUserSelectInput = (props) => {
    return (
        <ReferenceInput reference={'adminuser'} filter={{ filedtype: 'refernce' }} source="id" perPage={30} {...props} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    )
}

export const IncomePlanSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'NPORC', name: 'NPORC' },
            { id: 'ITR', name: 'ITR' },
            { id: 'GSTR', name: 'GSTR' },
        ]} {...props} />
    )
}



export const FileTypeSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Video', name: 'Video' },
            { id: 'Image', name: 'Image' },
            { id: 'Document', name: 'Document' },
            { id: 'Others', name: 'Others' },
        ]} {...props} />
    )
}

export const UserStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Decline', name: 'Decline' },
            { id: 'Blocked', name: 'Blocked' },
        ]} {...props} />
    )
}
export const ShopStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Suspend', name: 'Suspend' },
            { id: 'Deactive', name: 'Deactive' },
            { id: 'Block', name: 'Block' },
        ]} {...props} />
    )
}

export const MerchandiserStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Decline', name: 'Decline' },
        ]} {...props} />
    )
}

export const InfluencerStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Approved', name: 'Approved' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Reject', name: 'Reject' },
        ]} {...props} />
    )
}

export const SBOCareCategorySelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Task', name: 'Task' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Others', name: 'Others' },
        ]} {...props} />
    )
}



export const RefundDocumentStatus = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Approved', name: 'Approved' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Decline', name: 'Decline' },
        ]} {...props} />
    )
}

export const DigitalTaskStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Approved', name: 'Approved' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Rejected', name: 'Rejected' },
            { id: 'Cancel', name: 'Cancel' },
        ]} {...props} />
    )
}

export const WithdrawPaymentInfoSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Withdrawuser', name: 'Withdraw Active User' },
            { id: 'Agreementproof', name: 'Agreement Proof' },
            { id: 'Agreementwithdraw', name: 'Refund proof' },
            { id: 'CouponByBank', name: 'Coupon By Bank' },
            { id: 'CouponByApplication', name: 'Coupon By Application' },
            { id: 'RefundUserWorkingPayout', name: 'Refund user working payout' },
        ]} {...props} />
    )
}



export const DigitalTaskSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'DigitalContentWritter', name: 'Digital Content Writter' },
            { id: 'DigitalInfluencer', name: 'Digital Influencer' },
        ]} {...props} />
    )
}

export const UserPendingStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Pending', name: 'Pending', not_available: true },
            { id: 'Active', name: 'Active' },
            { id: 'Decline', name: 'Decline' },
        ]} {...props} disableValue="not_available" />
    )
}

export const UserActiveStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Active', name: 'Active', not_available: true },
            { id: 'Suspended', name: 'Suspended' },
        ]} {...props} disableValue="not_available" />
    )
}
export const UserDeclineStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Decline', name: 'Decline', not_available: true },
            { id: 'Active', name: 'Active' },
        ]} {...props} disableValue="not_available" />
    )
}
export const UserSuspendedStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Suspended', name: 'Suspended', not_available: true },
            { id: 'Active', name: 'Active' },
        ]} {...props} disableValue="not_available" />
    )
}


export const UserTeamSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Sales', name: 'Sales', },
            { id: 'Promotion', name: 'Promotion' },
            { id: 'Others', name: 'Others' },
        ]} {...props} />
    )
}

export const UserPaidSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Online', name: 'Online', },
            { id: 'Direct Bank', name: 'Direct Bank' },
        ]} {...props} />
    )
}

export const UserBlockedStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Blocked', name: 'Blocked', not_available: true },
            { id: 'Active', name: 'Active' },
        ]} {...props} disableValue="not_available" />
    )
}

export const WithdrawRequestStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Pending', name: 'Pending' },
            { id: 'Cancelled', name: 'Cancelled' },
            { id: 'Sent', name: 'Sent' },
            { id: 'Decline', name: 'Decline' },
        ]} {...props} />
    )

}

export const PurchasePartnerDetailsStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Pending', name: 'Pending' },
            { id: 'Approved', name: 'Approved'}, 
            { id: 'Rejected', name: 'Rejected'}, 
        ]} {...props} />
    )

}
export const PlanUpgradeRequestStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Pending', name: 'Pending' },
            { id: 'Cancelled', name: 'Cancelled' },
            { id: 'Approved', name: 'Approved' }
        ]} {...props} />
    )

}

export const WithdrawRequestEditStatusSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Pending', name: 'Pending', not_available: true },
            { id: 'Sent', name: 'Sent', not_available: true },
            { id: 'Decline', name: 'Decline' },
        ]} {...props} disableValue="not_available" />
    )

}
export const WithdrawRequestTypeSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'VideoWallet', name: 'VideoWallet' },
            { id: 'UserWallet', name: 'UserWallet' },
        ]} {...props} />
    )
}
export const JoinTypeSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Referral', name: 'Referral' },
            { id: 'Teamleader', name: 'Teamleader' },
        ]} {...props} />
    )
}

export const PlanSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Basic', name: 'Basic' },
            { id: 'Premium', name: 'Premium' },
        ]} {...props} />
    )
}

export const PaymentMethodSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Gpay/Phonepay', name: 'Gpay/Phone Pay' },
            { id: 'Other Methods', name: 'Other Methods' },
            { id: 'Un Verified', name: 'Un Verified' },
            { id: 'Bulck Method', name: 'Bulck Method' },
        ]} {...props} />
    )
}


export const MerchandiserSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Beta Plan Rs: 20,000', name: 'Beta Plan Rs: 20,000' },
            { id: 'Business Plan Rs: 75,000', name: 'Business Plan Rs: 75,000' },
            { id: 'Pro Plan Rs: 65,000', name: 'Pro Plan Rs: 65,000' },
        ]} {...props} />
    )
}



export const BulckPaymentSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Yes', name: 'Yes' },
            { id: 'No', name: 'No' },
        ]} {...props} />
    )
}




export const UserSelectInput = (props) => {
    return (
        <ReferenceInput reference={'user'} label="Sponsor" filter={{ filedtype: 'refernce' }} source="profile_id" perPage={30} {...props} >
            <AutocompleteInput optionText={(record) => { return record ? (record.name ? record.name + ' - ' : '') + (record.profile_id ? record.profile_id : '') : '' }} />
        </ReferenceInput>
    )
}

export const BonusTypeSelectInput = (props) => {
    return (
        <SelectInput choices={[
            { id: 'Welcome Bonus', name: 'Welcome Bonus' },
            { id: 'Buy Back Point', name: 'Buy Back Point' },
            { id: 'Intro Bonus', name: 'Intro Bonus' },
            { id: 'Working Wallet', name: 'Working Wallet' },
            { id: 'Non Working Wallet', name: 'Non Working Wallet' },
            { id: 'Affiliate Wallet', name: 'Affiliate Wallet' },
        ]} {...props} />
    )
}








