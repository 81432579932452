import * as React from "react";
import { Show, SimpleShowLayout, useRecordContext, Button, TextField, FunctionField } from 'react-admin';
const PageTitle = () => {
  const record = useRecordContext();
  return <span>Plan upgrade request for {record ? `${record.profile_id}` : ''}</span>;
};
const PurchasePartnerDetailsDetail = ({ ...props }) => (

  <Show title={<PageTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="user.profile_id" label="Profile Id" />
      <TextField source="phone" label="Phone" />
      <TextField source="email" label="Email" />
      <TextField source="order_id" label="Order Id" />
      <TextField source="services" label="Services" />
      <TextField source="purchase_date" label="Purchase Date" />
      <TextField label="Action by" source="actionby.name" />
      <TextField label="Action at" source="action_at" />
      <TextField label="User Action by" source="useractionby.name" />
      <TextField label="User Action at" source="user_action_at" />
      <TextField label="Reason" source="rejected_reason" />
      <FunctionField source="status" label="Status" render={record => {
        if (record['status'] === 'Pending') {
          return (<Button style={{ color: 'orange', border: '1px solid' }} label="Pending" size="small"></Button>)
        } else if (record['status'] === 'Approved') {
          return (<Button style={{ color: 'green', border: '1px solid' }} label="Approved" size="small"></Button>)
        } else if (record['status'] === 'Cancelled') {
          return (<Button style={{ color: 'blue', border: '1px solid' }} label="Cancelled" size="small"></Button>)
        } else if (record['status'] === 'Rejected') {
          return (<Button style={{ color: 'red', border: '1px solid' }} label="Rejected" size="small"></Button>)
        }
      }} />
    </SimpleShowLayout>
  </Show>
);
export default PurchasePartnerDetailsDetail