import React, { useState, useMemo } from 'react'
import {
    List, Datagrid, Filter, Toolbar,DateInput, SaveButton, Button, SimpleForm, useRefresh, SelectInput, required, FormDataConsumer, useNotify, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

import IconCancel from "@material-ui/icons/Cancel";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";
import { actionOfMicroAndContentWritter } from '../../Model/actionOfMicroAndContentWritter';
import { DigitalTaskStatusSelectInput,AdminUserSelectInput } from '../../UI/Input/SelectInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <AdminUserSelectInput label="Admin User" source="adminuser" alwaysOn />
        <DigitalTaskStatusSelectInput label="Status" source="status" alwaysOn />
        <DateInput source="current_date" lable="Date" alwaysOn/>
    </Filter>
)
const SanitizedGrid = ({ basePath, ...props }) => {
    return <Grid {...props} />;
};
const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Submit" redirect={null} submitonenter={false} />
        {!props.saving && <Button label="ra.action.cancel" onClick={props.onCancel}>
            <IconCancel />
        </Button>}
    </Toolbar>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}


const MicroTaskList = ({ ...props }) => {


    const [open, setOpen] = useState(false);
    const refresh = useRefresh()
    const notify = useNotify()
    const recordold = useRecordContext();
    // const {onClose, record } = this.props
    const [inputValue, setInputValue] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [recordStatus, setRecordStatus] = useState(null);

    const handleOpenDialog = (record) => {
        console.log(record);
        console.log('record');
        setSelectedId(record.id);
        setRecordStatus(record.status);
        setOpen(true);
    }
    const handleCloseDialog = () => setOpen(false);


    // Function to handle submit action in the dialog
    const handleSubmit = (record) => {

        const data = {
            "rid": selectedId,
            "type": 'microtask',
            "status": record.status,
            "reason": record.decline_reason
        }
        actionOfMicroAndContentWritter(data, selectedId, (res) => {
            refresh()
            notify(res.message)
        }, (res) => {
            notify(res.message, 'warning')
        })
        setOpen(false);

    };

    const OpenDialogButton = ({ onClick }) => {
        const record = useRecordContext(); // Access the current record context
        if (!record) return null; // Ensure record exists
        return (

            <Button style={{ color: 'purple', border: '1px solid' }} onClick={() => onClick(record)} label="Update Action" size="small"></Button>
        );
    };


    const choices = useMemo(() => {

        if (recordStatus === 'Pending') {
            return [
                { id: 'Approved', name: 'Approved' },
                { id: 'Rejected', name: 'Rejected' },
            ];
        } else if (recordStatus === 'Approved') {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Rejected', name: 'Rejected' },
            ];
        } else {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Approved', name: 'Approved' },
            ];
        }
    }, [recordStatus]);


    return (
        <List {...props} title="Microtask" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <ProfileidFiled source="profile_id" label="Profile Id" />
                <TextField source='name' label="Name" />
                <FunctionField source="url" label="Link" render={record => {
                    return (<Button target="_blank" style={{ color: 'green', border: '1px solid' }} href={record.url} label="Link" size="small"></Button>)

                }} />
                <FunctionField source="status" label="Status" render={record => {

                    if (record['status'] === 'Rejected') {
                        return (<span style={{ color: 'red' }}>Reject</span>)
                    } else if (record['status'] === 'Pending') {
                        return (<span style={{ color: 'orange' }} >Pending</span>)
                    }
                    else {
                        return (<span style={{ color: 'green' }}>Approved</span>)
                    }
                }} />
                <TextField source="amount" label="Amount" />
                <TextField source="title" label="Title" />
                <TextField source='created_at' label="Date" />
                <TextField source="reason" label="Reason" />
                <TextField source="action_at" label="Action At" />
                <TextField source="actionBy.name" label="Action By" />
                {useCheckPermissions('Microtask_Edit') ? <OpenDialogButton onClick={handleOpenDialog} /> : null}

            </Datagrid>

            {/* Dialog Component */}
            <Dialog maxWidth='900px' open={open} onClose={handleCloseDialog}>
                <DialogTitle> Record Id - {recordStatus}</DialogTitle>

                <SimpleForm sx={{ minWidth: '400px', }} onSubmit={(record) => handleSubmit(record)} toolbar={<FormToolbar onCancel={() => { handleCloseDialog() }} />} >
                    <SanitizedGrid container spacing={3} fullWidth>

                        {<Grid item sm={12} lg={12}>
                            <SelectInput validate={required()} source="status" label="Status" fullWidth choices={choices}{...props} />

                        </Grid>}
                        <Grid item sm={12} lg={12}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    formData.status === "Rejected" && (
                                        <TextInput source="decline_reason" label="Reason" fullWidth resettable multiline validate={required()} />
                                    )
                                }
                            </FormDataConsumer>

                        </Grid>
                    </SanitizedGrid>
                </SimpleForm>


            </Dialog>

        </List>
    )
}


export default MicroTaskList 