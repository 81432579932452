import React from "react";
import { Edit, useRecordContext, required, FormDataConsumer, FunctionField, SimpleForm, TextInput, Toolbar, ListButton, SaveButton } from "react-admin";

import {  PurchasePartnerDetailsStatusSelectInput } from "../../UI/Input/SelectInput";

import Grid from "@material-ui/core/Grid";


const PageTitle = () => {
  const record = useRecordContext();
  return <span>Withdraw Request for {record ? `${record.profile_id}` : ''}</span>;
};
const SanitizedGrid = ({ basePath, ...props }) => {
  return <Grid {...props} />;
};
const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    {!props.saving && <ListButton label="Cancel" />}
  </Toolbar>
);

const WithdrawRequestEdit = (props) => {

  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FormToolbar />} redirect="list" defaultValues={{ action: "full" }}>
        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="Profile ID" source="user.profile_id" fullWidth />
          </Grid>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="phone" source="phone" fullWidth />
          </Grid>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="email" source="email" fullWidth />
          </Grid>
        </SanitizedGrid>

        <SanitizedGrid container spacing={3} fullWidth>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="services" source="services" fullWidth />
          </Grid>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="purchase_date" source="purchase_date" fullWidth />
          </Grid>
          <Grid item sm={12} lg={4}>
            <TextInput disabled label="order_id" source="order_id" fullWidth />
          </Grid>
        </SanitizedGrid>


        <SanitizedGrid container spacing={3} fullWidth>

          <Grid item sm={12} lg={6}>
            <FunctionField source="status" label="Status" render={record => {
              if (record['status'] === 'Pending') {
                return (<PurchasePartnerDetailsStatusSelectInput validate={required()} label="Status" fullWidth source="status" />)
              } else {
                return (<PurchasePartnerDetailsStatusSelectInput disabled fullWidth label="Current_status" source="status" />)
              }
            }} />
          </Grid>
          <Grid item sm={12} lg={6}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.status === "Rejected" && (
                  <TextInput source="rejected_reason" label="Reason" fullWidth validate={required()} />
                )
              }
            </FormDataConsumer>

          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </Edit>
  );
};

export default WithdrawRequestEdit;
