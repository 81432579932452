import * as React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField, useRecordContext, Link } from 'react-admin';


const PurchasePartnerServiceDetail = ({ ...props }) => (

    <Show title="Purchase Partner Service detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="user.name" label="Name" />
            <TextField source="user.profile_id" label="Profile Id" />
            <TextField source="phone" label="Active Contact Number" />
            <TextField source="whatsapp" label="Whatsapp" />
            <TextField source="email" label="Active Email Id" />
            <TextField source="services" label="Service" />
            <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Rejected') {
                    return (<span style={{ color: 'red' }}>Rejected</span>)
                } else if (record['status'] === 'Pending') {
                    return (<span style={{ color: 'orange' }} >Pending</span>)
                }
                else if (record['status'] === 'Cancelled') {
                    return (<span style={{ color: 'blue' }} >Cancelled</span>)
                }
                else {
                    return (<span style={{ color: 'green' }}>Approved</span>)
                }
            }} />
            <TextField source="rejected_reason" label="Reason" />
            <TextField source="action_at" label="Action At" />
            <TextField source="actionby.name" label="Action By" />
            <TextField source="user_action_at" label="User Action At" />
            <TextField source="useractionby.name" label="User Action By" />
            <TextField source="created_at" label="Created at" />
            <TextField source="updated_at" label="Updated At" />
        </SimpleShowLayout>
    </Show>
);
export default PurchasePartnerServiceDetail